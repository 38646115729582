.wrapper {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  margin-bottom: 0.5em;
}

.controls {
  flex-basis: 0;
  flex-grow: 1;
  margin-right: 1em;
}

.explanation {
  flex-basis: 0;
  flex-grow: 1;
}
