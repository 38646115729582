.choice {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  margin-bottom: 1em;
}

.choice > button {
  width: 38px;
}

.choiceValue {
  flex-grow: 1;
  flex-basis: 0;
  margin: 0.5em;
  cursor: pointer;
}

.choiceExplanation {
  flex-grow: 1;
  flex-basis: 0;
}

.fullWidth {
  display: block;
}
